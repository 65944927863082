@media only screen and (max-width: 800px) {
  .title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    flex-direction: column;
    /* background-color: red; */
    /* font-size: 100%; */
  }

  .headline-items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    /* background-color: red; */
    padding: 0 10px;
  }
  .row-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /* background-color: green; */
  }
  .form-styling {
    width: 100%;
    position: relative;
    right: 30px;
    /* background-color: red; */
  }

  .title-divider {
    object-fit: contain;
    width: 100%;
    margin-bottom: 0;
  }
  .line-divider {
    height: 120px;
    width: 1000px;
    color: green;
  }

  .image-positioning-style {
    object-fit: "contain";
    width: 95%;
  }

  .text-style {
    color: white;
    font-size: 0.8rem;
    margin: 0;
    padding-bottom: 2px;
  }
  .custom-input {
    font-size: 1rem;
    position: relative;
    left: 5px;
    bottom: 25px;
    width: 80%;
    font-family: "Rajdhani";
    border: none;
    background-color: transparent;
    outline: none;
    color: white;
  }

  /* BUTTONS */

  .submit-button {
    width: 60%;
    height: 100px;
    cursor: pointer;
    object-fit: contain;
    padding-top: 5px;
  }

  /* Hover state using a pseudo-class */
  .submit-button:hover {
    width: 65%;
    height: 110px;
    transition: 0.5s;
  }
}
