/* body {
  background-image: url("./images/top.webp"), url("./images/bottom.webp"),
    url("./images/third.webp");
  background-size: cover;
} */

/* @font-face {
  font-family: "Medium"; 
  src: local("Rajdhani"),
    url("./fonts/Rajdhani-Medium.ttf") format("truetype");
} */
/* @font-face {
  font-family: 'Rajdhani';  
  src: url('./fonts/Rajdhani-Medium.ttf') format('truetype'),
       url('./fonts/Rajdhani-Light.ttf') format('truetype');
} */

/* 
Use justify-content: Remember that this CSS property aligns items horizontally and accepts the following values:
    flex-start: Items align to the left side of the container.
    flex-end: Items align to the right side of the container.
    center: Items align at the center of the container.
    space-between: Items display with equal spacing between them.
    space-around: Items display with equal spacing around them.

Now use align-items: This CSS property aligns items vertically and accepts the following values:
    flex-start: Items align to the top of the container.
    flex-end: Items align to the bottom of the container.
    center: Items align at the vertical center of the container.
    baseline: Items display at the baseline of the container.
    stretch: Items are stretched to fit the container.


Using flex-direction. This CSS property defines the direction items are placed in the container, and accepts the following values:
    row: Items are placed the same as the text direction.
    row-reverse: Items are placed opposite to the text direction.
    column: Items are placed top to bottom.
    column-reverse: Items are placed bottom to top. */

/*
I added the font by downloading it from react library and using directly\


selector brings keyboard
add loading images/placeholders
footer1 do not have space between like footer2
 */

.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  /* justify-content: space-between; */
  /* height: 100vh; */
  /* padding-left: 50px; */
}

.title-img {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  flex-direction: row;
}

.title-divider{
  object-fit: contain;
  width: 100%;
  margin-bottom: 50px;
}

.row-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* height: 100vh; */
  width: 100%;
  /* background-color: red; */

  /* padding: 50px; */
}

.bar-styling {
  position: fixed;
  left: 1px;
  top: 50px;
}

.form-styling {
  width: 50%;
  margin-left: 80px;
  /* position: relative; */
  /* left: 100px; */
}

.headline-items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  /* background-color: red; */
  padding: 20px;
}

.label-textfield {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.text-style {
  color: white;
  font-size: 25px;
  margin: 0;
  padding-bottom: 6px;
}
.image-positioning-style {
  object-fit: "contain";
  width: 65%;
}

.button-stack {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  /* background-color: pink; */
}

.custom-input {
  font-size: 25px;
  position: relative;
  left: 20px;
  bottom: 40px;
  width: 100%;
  font-family: "Rajdhani";
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
}

.payment-stack {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: pink; */
}

body {
  background-image: url("./images/bg1.webp");
  /* background-image: url("./images/bg1.png"), url("./images/bg2.png"),
      url("./images/bg3.png"); */
  background-size: cover;
  font-family: "Rajdhani";
}

/*  */
.center-div {
  display: "flex";
  justify-content: center;
  /* align-items: center; */
}

/* BUTTONS */

.submit-button {
  width: 30%;
  height: 100px;
  cursor: pointer;
  object-fit: contain;
  padding-top: 35px;
}

/* Hover state using a pseudo-class */
.submit-button:hover {
  width: 31%;
  height: 110px;
  transition: 0.5s;
}

.paystack-button {
  height: 40px;
  text-align: center;
  width: 200px;
  color: #5ef6ff;
  font-size: normal;
  margin-top: 50px;

  background-color: transparent;
  border: 1px solid #5ef6ff;
  cursor: pointer;
}

/* Hover state using a pseudo-class */
.paystack-button:hover {
  height: 40px;
  text-align: center;
  width: 200px;
  color: #5ef6ff;
  font-size: large;

  background-color: transparent;
  border: 1px solid #5ef6ff;
  cursor: pointer;
  /* border-radius: 10px; */
  transition: 0.5s;
}

/* Hover state using a pseudo-class */
/* .paystack-button:hover {
  width: 30%;
  height: 100px;
  cursor: pointer;
  object-fit: contain;
  padding-top: 35px;
  border: none;
  transition: 0.5s;
} */

/* const [mobile, setMobile] = useState(false);
useEffect(() => {
  function handleResize() {
    if (document.documentElement.clientWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  handleResize();

  window.addEventListener("resize", handleResize);

  return () => window.removeEventListener("resize", handleResize);
}, []); */
